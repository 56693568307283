import demoGifSrc from '../img/demo.gif';

function onDemoGifLoaded() {
    // Replace background with animated gif
    document.getElementById("background").style.backgroundImage = `url(${demoGifSrc})`;
}

let demoGif = document.createElement('img');
demoGif.setAttribute('src', demoGifSrc);
demoGif.setAttribute('style', 'display: none');
demoGif.addEventListener('load', onDemoGifLoaded);
document.body.appendChild(demoGif);